<template>
  <div class="page container p-4">
    <div class="columns">
      <div class="column">
        <div class="has-text-centered">
          <img
            class="anon-avatar"
            src="@/assets/img/anon-avatar.png"
            alt="Solicita una nueva contraseña"
          >
        </div>
        <b-field>
          <b-input
            ref="email"
            v-model="username"
            type="email"
            placeholder="Usuario (email)"
            required
            icon-pack="fas"
            icon="envelope"
            maxlength="100"
            @blur="validateForm"
          />
        </b-field>
        <div class="mt-3">
          <b-button
            type="is-primary is-uppercase"
            expanded
            @click="requestChange"
          >
            Solicitar cambio
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  title: 'Solicitar cambio de contraseña',
  name: 'RequestChangePassword',
  components: {},
  data: function () {
    return {
      isValid: false,
      username: ''
    }
  },
  computed: {},
  mounted() {

  },
  methods: {
    validateForm() {
      const validateFields = ["email"];
      let isValidFormData = true

      validateFields.forEach((item) => {
        if (this.$refs.hasOwnProperty(item)) {
          const itemIsValid = this.$refs[item].checkHtml5Validity()
          isValidFormData = isValidFormData && itemIsValid
        }
      })

      this.isValid = isValidFormData
    },
    async requestChange() {
      this.validateForm()

      if (this.isValid) {
        try {
          this.$store.dispatch('initLoading')
          const response = await this.$api.user.requestChangePassword(this.username)
          this.$store.dispatch('finishLoading')

          this.$msg.success(response.message)

          this.$router.push('login')

        } catch (error) {
          const data = error.response.data
          this.$msg.error(data.message)
        }
      }
    }
  }
}
</script>

<style type="scss" scoped>
</style>
